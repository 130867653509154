import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {
  ConnectResult,
  ConnectService,
} from 'src/app/basic/service/core/connect.service';
import { FileService } from 'src/app/basic/service/core/file.service';
import { ToastService } from 'src/app/basic/service/ionic/toast.service';
import { PromiseService } from 'src/app/basic/service/util/promise.service';

export class Education {
  ctgo_job_position_name: '';
  company_id: 0;
  ctgo_safe_job_name: '';
  user_id: 0;
  ctgo_job_position_id: 0;
  user_name: '';
  company_name: '';
  user_safe_job_id: 0;
  ctgo_safe_job_id: 0;
  checked = false;
  ctgo_job_position_name_kr: '';
  ctgo_safe_job_name_kr: '';
  occupation_name: '';
  ctgo_construction_name: '';
}
@Component({
  selector: 'app-search-attendance',
  templateUrl: './search-attendance.component.html',
  styleUrls: ['./search-attendance.component.scss'],
})
export class SearchAttendanceComponent implements OnInit {
  @Input() project_id: number;
  @Input() company_id: number;
  @Input() allState: boolean = false;
  @Input() editable: boolean = false;
  @Input() multiple: boolean = false;
  @Input() educationType: boolean = false;
  @Input() value;
  @Input() title = '출석진행자';
  @Input() isWarningAlarm: boolean = false;

  form = {
    user_type: '',
    project_id: 0,
    company_id: 0,
    search_text: '',
  };

  res: ConnectResult<Education>;
  tempRes: ConnectResult<Education>;

  selectAll: boolean;
  values: Education[] = [];
  newValues: Education[] = [];

  allBusinessRegisterNoChecked: boolean = false;

  constructor(
    private connect: ConnectService,
    private toast: ToastService,
    private _modal_: ModalController,
    private promise: PromiseService,
    private file: FileService
  ) {}

  ngOnInit() {
    this.form.project_id = this.project_id;
    this.form.company_id = this.company_id;
    this.form.user_type = '관리자';
    // if (this.educationType) {
    //   this.form.user_type = '관리자';
    // } else this.form.user_type = '작업자';
    this.get();
    this.getItem();
  }

  async search() {
    if (!this.isWarningAlarm) {
      this.get();
    } else {
      if (!this.form.search_text || this.form.search_text.trim() === '') {
        this.res = this.file.clone(this.tempRes);
      } else {
        this.res = this.file.clone(this.tempRes);
        this.res.rsMap = this.res?.rsMap.filter((item) => {
          if (item.company_name.includes(this.form.search_text)) return true;
          if (item.user_name.includes(this.form.search_text)) return true;
          if (item.ctgo_job_position_name.includes(this.form.search_text))
            return true;
          if (item.ctgo_safe_job_name.includes(this.form.search_text))
            return true;
          return false;
        });
      }
    }
  }

  changeUserType() {
    this.get();
  }

  async get() {
    if (this.isWarningAlarm) {
      this.tempRes = await this.connect.run(
        '/emergency/danger_warning_alarm_receiver_cadidate_list/get'
      );
      if (this.tempRes.rsCode === 0) {
        if (this.value) {
          this.tempRes.rsMap?.filter((item) => {
            if (this.value.indexOf(item.user_id) > -1) {
              item.checked = true;
            }
            item.ctgo_job_position_name = item.ctgo_job_position_name_kr;
            item.ctgo_safe_job_name = item.ctgo_safe_job_name_kr;
          });
        }
        this.res = this.file.clone(this.tempRes);
      } else if (this.tempRes.rsCode === 1008) {
        // 아무것도 안함
      } else {
        this.toast.present({ color: 'warning', message: this.tempRes.rsMsg });
      }
    } else {
      this.res = await this.connect.run(
        '/category/certify/education/manager/get',
        this.form
      );
      if (this.res.rsCode === 0) {
        if (this.value) {
          this.res?.rsMap?.filter((item) => {
            if (this.value.indexOf(item.user_id) > -1) {
              item.checked = true;
            }
          });
        }
      } else if (this.res.rsCode === 1008) {
        // 아무것도 안함
      } else {
        this.toast.present({ color: 'warning', message: this.res.rsMsg });
      }
    }
  }

  async getItem() {
    await this.promise.wait(() => this.res);
    if (this.value)
      [
        this.res?.rsMap?.filter((item) => {
          if (this.value.indexOf(item.user_id) > -1) {
            this.values.push(item);
            console.log(this.values);
            item.checked = true;
          }
        }),
      ];
  }

  selectItem(item) {
    item.checked = !item.checked;
    if (item.checked) {
      if (!this.values.includes(item)) this.values.push(item);
    } else {
      const index = this.values.findIndex((data) => data === item);
      this.values.splice(index, 1);
    }
  }

  deleteData(i, name) {
    console.log(i);
    this.res.rsMap.forEach((item) => {
      if (item.user_id === name.user_id) {
        item.checked = false;
      }
    });
    this.values.splice(i, 1);
  }

  dismiss() {
    this._modal_.dismiss();
  }
  submit() {
    this._modal_.dismiss(this.values);
  }
}
