import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import QRious from 'qrious';
import { ConnectService } from 'src/app/basic/service/core/connect.service';

@Component({
  selector: 'app-qr-tbm',
  templateUrl: './qr-tbm.page.html',
  styleUrls: ['./qr-tbm.page.scss'],
})
export class QrTbmPage implements OnInit {
  @Input() item;

  qr = null;

  constructor(
    private navParams: NavParams,
    private connect: ConnectService,
    private _modal: ModalController
  ) {}

  ngOnInit() {
    this.generatorQrcode();
  }

  generatorQrcode() {
    this.qr = new QRious({
      element: document.getElementById('qrious'),
      size: 250,
      value: `{"type":"TBM",
      "tbm_id":"${this.navParams.data.item.tbm_id}",
      "project_id":"${this.navParams.data.item.project_id}",
      "company_id":"${this.navParams.data.item.company_id}"
    }`,
    });
  }

  downloadQR() {
    let link = document.createElement('a');
    link.download = 'QRcode';
    link.href = this.qr.image.currentSrc;
    link.click();
  }

  dismiss() {
    this._modal.dismiss(true);
  }
}
