import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApprovalZoomComponent } from '../approval-zoom/approval-zoom.component';
@Component({
  selector: 'app-table-document',
  templateUrl: './table-document.component.html',
  styleUrls: ['./table-document.component.scss'],
})
export class TableDocumentComponent implements OnInit {
  isDocumentHidden: boolean = false;

  @ViewChild('documentLayout') documentLayout: ElementRef<HTMLElement>;

  @Input() title = '상세';

  constructor(private _modal: ModalController) {}

  ngOnInit() {}

  async mobilePopup() {
    if (window.innerWidth <= 768) {
      const cloneEl = this.documentLayout.nativeElement.cloneNode(true);

      const modal = await this._modal.create({
        component: ApprovalZoomComponent,
        componentProps: {
          zoomEl: cloneEl,
          title: this.title,
        },
      });
      modal.present();
    }
  }
}
