import { ToastService } from 'src/app/basic/service/ionic/toast.service';
import { Component, ElementRef, Input, OnInit } from '@angular/core';
import {
  ConnectResult,
  ConnectService,
} from 'src/app/basic/service/core/connect.service';
import { ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { PromiseService } from 'src/app/basic/service/util/promise.service';
import { LanguagePackService } from 'src/app/basic/service/core/language-pack.service';
import { FileService } from 'src/app/basic/service/core/file.service';

export type ProjectSearchType = 'SIGNUP' | 'SIGNUPLH' | null;
export class ProjectItem {
  project_name: string;
  project_id: number;
  project_code: string;
}

export class DangerListItem {
  area_risk_id: number; // 위험 지역 ID
  ctgo_area_risk_id: number; // 위험 지역 유형 ID
  area_risk_name: string; // 위험 지역 명
  ctgo_area_risk_name: string; // 위험 지역 유형 명
}
@Component({
  selector: 'app-search-danger-list',
  templateUrl: './search-danger-list.component.html',
  styleUrls: ['./search-danger-list.component.scss'],
})
export class SearchDangerListComponent implements OnInit {
  @Input() value;
  @Input() form = {
    project_id: 0,
    search_text: '',
  };
  res: ConnectResult<DangerListItem>;
  tempRes: ConnectResult<DangerListItem>;

  allState: boolean = false; // 전체현장을 선택했는지? 위험지역에서는 의미 없음
  selectedItem: DangerListItem;

  constructor(
    private el: ElementRef<HTMLElement>,
    private connect: ConnectService,
    private _modal: ModalController,
    private promise: PromiseService,
    private toast: ToastService,
    public languagePack: LanguagePackService,
    private file: FileService
  ) {}

  ctgoRiskList = [
    '전체',
    '전기실',
    '기계실',
    '저수조',
    '기계식 지하주차장',
    '유해 위험물 저장소',
    '고압변전 지역',
    '양중 지역',
  ];

  selectedCtgoRisk: number = 0;

  /**
   * 위험 유형 변경
   */
  selectedChange() {
    this.form.search_text = '';
    this.get();
  }

  ngOnInit() {
    // this.test();
  }

  public async test() {
    if (!environment.test.core.test) return;

    const el = this.el.nativeElement;

    // 가짜 데이터 삽입
    await this.promise.wait();

    // 검색
    el.querySelector('[name=search_text]').dispatchEvent(
      new Event('buttonClick')
    );
    await this.promise.wait();

    // 가장 위의 항목 선택
    el.querySelector('[name=area_risk]').dispatchEvent(new Event('click'));
    await this.promise.wait();

    // 선택함
    el.querySelector('[name=submit]').dispatchEvent(new Event('click'));
  }

  async get() {
    this.tempRes = await this.connect.run(
      '/project/inspection/risk/search_risk_area/list',
      {
        project_id: this.form.project_id,
        ctgo_area_risk_id: this.selectedCtgoRisk,
      }
    );
    if (this.tempRes.rsCode === 0) {
      this.tempRes.rsMap.forEach((item) => {
        if (this.value === item.area_risk_id) this.selectedItem = item;
      });

      this.res = this.file.clone(this.tempRes);
      if (!this.value) this.allState = true;
    } else {
      this.toast.present({ color: 'warning', message: this.tempRes.rsMsg });
    }
  }

  async search() {
    console.log('tbm people search', this.form.search_text);
    if (this.form.search_text === '') {
      this.res = this.file.clone(this.tempRes);
    } else {
      this.res.rsMap = this.tempRes.rsMap.filter(
        (item) =>
          item.area_risk_name.includes(this.form.search_text) ||
          item.ctgo_area_risk_name.includes(this.form.search_text)
      );
    }
  }

  select() {
    this._modal.dismiss({
      selectedItem: this.selectedItem,
    });
  }
}
