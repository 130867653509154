import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { PromiseService } from 'src/app/basic/service/util/promise.service';
import { Color } from '@ionic/core';
import { LanguagePackService } from 'src/app/basic/service/core/language-pack.service';

export type AccidentType =
  | '전체'
  | '추락(떨어짐)'
  | '전도(넘어짐)'
  | '전도(깔림)'
  | '충돌(부딪힘)'
  | '낙하비례(물체에맞음)'
  | '붕괴(무너짐)'
  | '협착(끼임)'
  | '절단,베임,찔림'
  | '화재,폭발,파열'
  | '무리한동작'
  | '감전'
  | '기타';
@Component({
  selector: 'app-select-accident-unit',
  templateUrl: './select-accident-unit.component.html',
  styleUrls: ['./select-accident-unit.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectAccidentUnitComponent),
      multi: true,
    },
  ],
})
export class SelectAccidentUnitComponent
  implements OnInit, ControlValueAccessor
{
  @HostListener('click') onClick() {
    this.el.nativeElement
      .querySelector('[name=select]')
      .dispatchEvent(new Event('click'));
  }

  @Input() all: boolean = false;
  @Input() color: Color;
  @Input() label: string = '사고유형';
  @Input() placeholder: string = '선택';

  constructor(
    private el: ElementRef<HTMLElement>,
    private promise: PromiseService,
    public languagePack: LanguagePackService
  ) {}

  accidentList = [
    '추락(떨어짐)',
    '전도(넘어짐)',
    '전도(깔림)',
    '충돌(부딪힘)',
    '낙하비래(물체에맞음)',
    '붕괴(무너짐)',
    '협착(끼임)',
    '절단,배임,찔림',
    '화재,폭발,파열',
    '무리한동작',
    '감전',
    '기타',
  ];

  selectedAccident: AccidentType = '전체';

  ngOnInit() {
    if (this.all) {
      this.accidentList = [
        this.languagePack.getText('전체'),
        ...this.accidentList,
      ];
    }
  }

  //default setting
  @HostBinding('class.readonly') get classReadonly() {
    return this.readonly;
  }
  @HostBinding('class.disabled') get classDisabled() {
    return this.disabled;
  }
  @Input() readonly: boolean = false;
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Output() change = new EventEmitter();

  private _value: AccidentType;
  @Input() set value(v: AccidentType) {
    if (v !== this._value) {
      this._value = v;
      this.onChangeCallback(v);
      this.change.emit(v);
    }
  }
  get value() {
    return this._value;
  }
  writeValue(v: AccidentType): void {
    if (v !== this._value) {
      this._value = v;
      this.onChangeCallback(v);
      this.change.emit(v);
    }
  }

  private onChangeCallback = (v) => {};
  private onTouchedCallback = (v) => {};
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }
}
