import { UserService } from '../../../basic/service/core/user.service';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import {
  TbmLeaderItem,
  SearchTbmComponent,
} from '../../modal/search-tbm/search-tbm.component';
import { Color } from '@ionic/core';
import { ConnectService } from 'src/app/basic/service/core/connect.service';
import { LanguagePackService } from 'src/app/basic/service/core/language-pack.service';
import {
  DangerListItem,
  SearchDangerListComponent,
} from '../../modal/search-danger-list/search-danger-list.component';

@Component({
  selector: 'app-select-danger-list',
  templateUrl: './select-danger-list.component.html',
  styleUrls: ['./select-danger-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectDangerListComponent),
      multi: true,
    },
  ],
})
export class SelectDangerListComponent implements OnInit, ControlValueAccessor {
  @HostListener('click') onClick() {
    if (!this.disabled && !this.readonly) this.openModal();
  }

  @Input() project_id: number = 0;
  @Input() color: Color;
  @Input() label: string = '위험지역명 검색';
  @Input() readonly: boolean = false;
  @Output() selectedItemChange = new EventEmitter<DangerListItem>();
  @Input() text: string = '';
  @Output() selectedItem?: DangerListItem;
  isModalData: boolean = false;

  constructor(
    private connect: ConnectService,
    private _modal: ModalController,
    private changeDetector: ChangeDetectorRef,
    private user: UserService,
    public languagePack: LanguagePackService
  ) {}

  ngOnInit() {}

  public async openModal() {
    this.isModalData = true;
    const modal = await this._modal.create({
      component: SearchDangerListComponent,
      componentProps: {
        value: this.value,
        form: {
          search_text: '',
          project_id: this.project_id,
        },
      },
    });
    modal.present();
    const { data } = await modal.onDidDismiss();
    if (data) {
      this.selectedItem = <DangerListItem>data.selectedItem;

      this.value = this.selectedItem.area_risk_id;
      this.text = this.selectedItem.area_risk_name;
      this.selectedItemChange.emit(this.selectedItem);
    }
    this.isModalData = false;
  }

  /**
   * 데이터 전송
   */
  sendBindingData() {
    this.selectedItemChange.emit(this.selectedItem);
  }

  //default setting
  //@Input() readonly:boolean = false;
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Output() change = new EventEmitter();

  private _value: number;
  @Input() set value(v: number) {
    this.valueChange(v);
  }
  get value() {
    return this._value;
  }
  writeValue(v: number): void {
    this.valueChange(v);
  }

  async valueChange(v) {
    if (v !== this._value) {
      this._value = v ? v : 0;
      this.onChangeCallback(v);
      // this.get();
      this.change.emit(this.selectedItem);
    }
  }

  private onChangeCallback = (v) => {};
  private onTouchedCallback = (v) => {};
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }
}
