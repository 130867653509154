import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FutItem } from 'src/app/basic/service/core/file.service';

@Component({
  selector: 'app-file-preview-mobile',
  templateUrl: './file-preview-mobile.component.html',
  styleUrls: ['./file-preview-mobile.component.scss'],
})
export class FilePreviewMobileComponent implements OnInit {
  @Input() futItem: FutItem;
  @Input() readonly: boolean = false;

  @Output() buttonClick = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
