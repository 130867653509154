import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {
  ConnectService,
  ConnectResult,
} from 'src/app/basic/service/core/connect.service';
import { AlertService } from 'src/app/basic/service/ionic/alert.service';
import { ToastService } from 'src/app/basic/service/ionic/toast.service';
// import { ModalController } from '@ionic/angular';
// import { AlertService } from 'src/app/basic/service/ionic/alert.service';

export class TbmJobListForm {
  seq_no: number; // 순서
  risk_asment_id: number; // 위험성 평가서 ID
  risk_construction_name: string; // 공사명
  risk_factor_name: string; // 위험요인
  risk_unit_name: string; // 단위작업
  risk_plan_name: string; // 감소대책
}

@Component({
  selector: 'app-tbm-unit',
  templateUrl: './tbm-unit.page.html',
  styleUrls: ['./tbm-unit.page.scss'],
})
export class TbmUnitPage implements OnInit {
  @Input() jobList: TbmJobListForm[] = [];

  editable = {
    add: true,
    company_id: true,
    my_state: true,
  };
  res: ConnectResult<TbmJobListForm>;
  constructor(
    private _modal: ModalController,
    private alert: AlertService,
    private connect: ConnectService,
    private toast: ToastService
  ) {}

  ngOnInit() {}

  /**
   * return TbmJobList 삭제
   * @param tbmJob
   */
  deleteItem(tbmJob?: TbmJobListForm) {
    if (tbmJob) {
      const index = this.jobList.indexOf(tbmJob);
      if (index > -1) this.jobList.splice(index, 1);
    }
  }

  /**
   * 작업 목록 적용
   */
  applyJobList() {
    this._modal.dismiss({ tbmJob: this.jobList });
  }
}
