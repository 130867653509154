import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Color } from '@ionic/core';
import {
  ConnectResult,
  ConnectService,
} from 'src/app/basic/service/core/connect.service';
import { PromiseService } from 'src/app/basic/service/util/promise.service';

export interface Construction {
  ctgo_construction_id: number;
  ctgo_construction_name: string;
}
@Component({
  selector: 'app-select-construction-tbm',
  templateUrl: './select-construction-tbm.component.html',
  styleUrls: ['./select-construction-tbm.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectConstructionTbmComponent),
      multi: true,
    },
  ],
})
export class SelectConstructionTbmComponent
  implements OnInit, ControlValueAccessor
{
  @HostListener('click') onClick() {
    this.el.nativeElement
      .querySelector('[name=select]')
      .dispatchEvent(new Event('click'));
  }

  @Input() all: boolean = true;
  @Input() color: Color;
  @Input() label: string = '공종';
  @Input() placeholder: string = '선택';
  @Input() multiple: boolean = false;

  conList: Array<object> = [];

  private _project_id: number = 0;
  @Input() set project_id(v: number) {
    if (this._project_id !== v) {
      this._project_id = v;
      this.checkInputs();
    }
  }
  get project_id() {
    return this._project_id;
  }

  private _company_id: number = 0;
  @Input() set company_id(v: number) {
    if (this._company_id !== v) {
      this._company_id = v;
      this.checkInputs();
    }
  }
  get company_id() {
    return this._company_id;
  }

  res: ConnectResult<Construction>;

  constructor(
    private el: ElementRef<HTMLElement>,
    private connect: ConnectService,
    private promise: PromiseService
  ) {}

  ngOnInit() {
    this.checkInputs();
  }

  private async get() {
    console.log('공종선택', this.project_id, this.company_id);
    this.res = await this.connect.run('/tbm/detail/construction/list/get', {
      project_id: this.project_id,
      company_id: this.company_id,
    });
  }
  async checkInputs() {
    if (!this.project_id || this.project_id === 0) {
      this.res = new ConnectResult();
      this.res.rsCode = 1008;
      this.res.rsMsg = '현장을 선택해주세요.';
    } else if (!this.company_id || this.company_id === 0) {
      this.res = new ConnectResult();
      this.res.rsCode = 1008;
      this.res.rsMsg = '원청사를 선택해주세요.';
    } else {
      await this.get();
    }
  }

  //default setting
  @HostBinding('class.readonly') get classReadonly() {
    return this.readonly;
  }
  @HostBinding('class.disabled') get classDisabled() {
    return this.disabled;
  }
  @Input() readonly: boolean = false;
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Output() change = new EventEmitter();

  private _value: any = 0;
  @Input() set value(v: any) {
    if (v !== this._value) {
      this.valueChange(v);
    }
  }
  get value() {
    return this._value;
  }
  writeValue(v: any): void {
    if (v !== this._value) {
      this.valueChange(v);
    }
  }

  valueChange(v) {
    console.log('construction', v);
    this._value = v ? v : this.multiple ? [] : 0;
    this.onChangeCallback(v);
    this.change.emit(v);
    this.get();
  }

  private onChangeCallback = (v) => {};
  private onTouchedCallback = (v) => {};
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }
}
