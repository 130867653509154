import {
  Component,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Color } from '@ionic/core';
import { LanguagePackService } from 'src/app/basic/service/core/language-pack.service';
import { SearchDistrictComponent } from '../../modal/search-district/search-district.component';
import { ConnectResult, ConnectService } from 'src/app/basic/service/core/connect.service';
import { ToastService } from 'src/app/basic/service/ionic/toast.service';


@Component({
  selector: 'app-select-district',
  templateUrl: './select-district.component.html',
  styleUrls: ['./select-district.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectDistrictComponent),
      multi: true,
    },
  ],
})
export class SelectDistrictComponent implements OnInit, ControlValueAccessor {
  @HostListener('click') onClick() {
    if (!this.disabled && !this.readonly) {
        this.openModal();
    }
  }

  @Input() color: Color;
  @Input() label: string = '사업 지구';
  @Input() text: string;
  @Input() editable: boolean = false;
  @Input() allState: boolean = false;
  @Input() required: boolean = false;
  @Input() multiple: boolean = false;
  @Input() readonly: boolean = false;
  @Input() disabled: boolean = false;
  @Input() only_state: boolean = false;
  @Input() warningMsg: boolean = true;

  constructor(
    private _modal: ModalController,
    public languagePack: LanguagePackService,
    private connect:ConnectService,
    private toast:ToastService,
  ) {}

  res:ConnectResult;

  ngOnInit() {
  }

  /**
   * 사업 지구 검색
   */
   async get() {
    const url = 'https://hq.lh-skeeper.or.kr/hqapi/district/list';
    this.res = await this.connect.run(
      url,
      {
        hq_regional_id: 0,
      },
      { region: true }
    );
    if (this.res.rsCode !== 0) {
      this.toast.present({ color: 'warning', message: this.res.rsMsg });
    }else if(this.res.rsCode === 0){
      this.res.rsMap = this.res.rsMap.filter((item)=> {
        let districtUrl = item.district_url;
        if(!item.district_url.includes('http')){
          districtUrl = 'https://' + districtUrl;
        }
        return districtUrl === this.value;
      })
      if(this.res.rsMap.length > 0){
        const item = this.res.rsMap[0];
        // this.value = item.district_url;
        this.text = item.district_name;
        this.change.emit({value:item.district_url});
      }
    }
  }

  public async openModal() {
    const modal = await this._modal.create({
      component: SearchDistrictComponent,
      componentProps: {
        value: this.value,
      },
    });
    modal.present();
    const { data } = await modal.onDidDismiss();
    if (data) {
      this.text = data.district_name;
      this.value = data.district_url;
      this.change.emit({value:data.district_url});
    }
  }

  @Output() change = new EventEmitter();

  private _value: string;
  @Input() set value(v: string) {
    this.valueChange(v);
  }
  get value() {
    return this._value;
  }
  writeValue(v: string): void {    
      this.valueChange(v);

  }
  valueChange(v) {
    if(v !== this._value) {
      this._value = v;
      this.onChangeCallback(v);
      this.get();
      this.change.emit(v);
    }
  }

  private onChangeCallback = (v) => {};
  private onTouchedCallback = (v) => {};
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }
}
