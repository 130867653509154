import { Component, ElementRef, EventEmitter, forwardRef, HostBinding, HostListener, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { PromiseService } from 'src/app/basic/service/util/promise.service';
import { Color } from '@ionic/core';
// import { ApprovalAnswerType } from '../../confirm/approval/approval.component';
import { LanguagePackService } from 'src/app/basic/service/core/language-pack.service';
import { ModalController } from '@ionic/angular';
import { UserService } from 'src/app/basic/service/core/user.service';
// import { SearchDangerousAreaComponent } from '../../modal/search-dangerous-area/search-dangerous-area.component'

@Component({
  selector: 'app-select-danger',
  templateUrl: './select-danger.component.html',
  styleUrls: ['./select-danger.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectDangerComponent),
    multi: true
  }]
})
export class SelectDangerComponent implements OnInit, ControlValueAccessor {

  dangerArea = ['전체','전기실','기계실','저수조','기계식 지하주차장','유해 위험물 저장소','고압변전 지역','양중 지역'];

  @Input() color:Color;
  @Input() label:string = "위험지역유형";
  @Input() placeholder:string = "전체";
  @Input() inputNumber:number = 0;
  @Output() indexChange = new EventEmitter();

  constructor(
    private el: ElementRef<HTMLElement>,
    private promise: PromiseService,
    public languagePack: LanguagePackService,
    private _modal: ModalController,
    private user:UserService,
  ) { }

  ngOnInit() {}

  //default setting
  @HostBinding('class.readonly') get classReadonly() { return this.readonly }
  @HostBinding('class.disabled') get classDisabled() { return this.disabled }
  @Input() readonly:boolean = false;
  @Input() disabled:boolean = false;
  @Input() required:boolean = false;
  @Output() change = new EventEmitter();

  private _value:number = 0;
  @Input() set value(v) {
    console.log('tw test 0 1',v)
    this._value = this.inputNumber;
    if(v !== this._value) {
      console.log('tw test 1',v)
      this._value = v;
      this.onChangeCallback(v);
      this.change.emit(v);
    }
  }
  get value() {
    return this._value;
  }

  writeValue(v): void {
    console.log('tw test 0 2',v)
    
    if(v !== this._value) {
      console.log('tw test 2',v)
      this._value = v;
      this.onChangeCallback(v);
      this.change.emit(v);
    }
  }

  private onChangeCallback = (v) => { this.indexChange.emit(Number(v));};
  private onTouchedCallback = (v) => {};
  registerOnChange(fn: any): void { this.onChangeCallback = fn; }
  registerOnTouched(fn: any): void { this.onTouchedCallback = fn; }
}

