import { LanguagePackService } from './../../../basic/service/core/language-pack.service';
import { UserService } from 'src/app/basic/service/core/user.service';
import { ModalController } from '@ionic/angular';
import { ToastService } from 'src/app/basic/service/ionic/toast.service';
import {
  ConnectResult,
  ConnectService,
} from 'src/app/basic/service/core/connect.service';
import { Component, OnInit } from '@angular/core';

export class RegionItem {
  district_name: string;
  district_id: number;
  district_url: string;
  district_use_state: number;
  district_code: number;
}

@Component({
  selector: 'app-setting-modify',
  templateUrl: './setting-modify.component.html',
  styleUrls: ['./setting-modify.component.scss'],
})
export class SettingModifyComponent implements OnInit {
  language = 'korean';
  regionUrl: string = '';

  constructor(
    private connect: ConnectService,
    public languagePack: LanguagePackService,
    private toast: ToastService,
    private _modal: ModalController,
    private user: UserService
  ) {}

  res: ConnectResult<RegionItem>;

  async ngOnInit() {
    this.language = this.languagePack.language_type;
    // this.getRegions();
    const value = this.user.getMobileUrl();
    if (value) {
      this.regionUrl = value;
      console.log('모바일 URL', value);
    }
  }

  selectRegion() {
    console.log('select region', this.regionUrl);
    this.user.setMobileUrl(this.regionUrl);
  }

  dismiss() {
    this._modal.dismiss();
  }

  async changeLanguage() {
    await this.languagePack.setLanguage(this.language);
    this._modal.dismiss(true);
  }
}
