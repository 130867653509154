import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {
  ConnectResult,
  ConnectService,
} from 'src/app/basic/service/core/connect.service';
import { LanguagePackService } from 'src/app/basic/service/core/language-pack.service';
import { ToastService } from 'src/app/basic/service/ionic/toast.service';
import { PromiseService } from 'src/app/basic/service/util/promise.service';
import { environment } from 'src/environments/environment';

export class RegionalResult {
  hq_regional_name: string;
  hq_regional_entire_state: number;
  hq_regional_code: string;
  hq_regional_id: number;
}
export class DistrictResult {
  district_name: string;
  district_id: number;
  district_url: string;
  district_use_state: number;
  district_code: number;
}
@Component({
  selector: 'app-search-district',
  templateUrl: './search-district.component.html',
  styleUrls: ['./search-district.component.scss'],
})
export class SearchDistrictComponent implements OnInit {
  @Input() project_id: number;
  @Input() value;
  @Input() allState: boolean = false;
  @Input() editable: boolean = false;
  @Input() multiple: boolean = false;
  @Input() only_state: boolean = false;

  form = {
    hq_regional_id: 0,
    search_text: '',
  };
  res: ConnectResult<DistrictResult>;
  regionRes: ConnectResult<RegionalResult>;

  selectAll: boolean = false;
  values: DistrictResult[] = [];
  newValues: DistrictResult[] = [];

  allBusinessRegisterNoChecked: boolean = false;

  constructor(
    private el: ElementRef<HTMLElement>,
    private connect: ConnectService,
    private _modal_: ModalController,
    private toast: ToastService,
    private promise: PromiseService,
    public languagePack: LanguagePackService
  ) {}

  ngOnInit() {
    this.test();
    this.getRegionList();
  }

  private async test() {
    if (!environment.test.core.test) return;

    const el = this.el.nativeElement;

    // 가짜 데이터 삽입
    await this.promise.wait();

    // 가장 위엣놈 클릭
    el.querySelector('[name=item]').dispatchEvent(new Event('click'));
    await this.promise.wait();

    // 적용
    el.querySelector('[name=submit]').dispatchEvent(new Event('click'));
  }

  /**
   * 지역 본부 검색
   */
  async getRegionList() {
    const url =
      'https://hq.lh-skeeper.or.kr/hqapi/category/organization/regional/get';
    this.regionRes = await this.connect.run(url, null, { region: true });
    if (this.regionRes.rsCode !== 0) {
      this.toast.present({ color: 'warning', message: this.res.rsMsg });
    }else if (this.regionRes.rsCode === 0){
      if (this.regionRes?.rsMap?.length > 0) {
        const region = this.regionRes?.rsMap[0];
        this.form.hq_regional_id = region.hq_regional_id;
      }
    }
    this.getDistrictList();
  }

  /**
   * 사업 지구 검색
   */
  async getDistrictList() {
    const url = 'https://hq.lh-skeeper.or.kr/hqapi/district/list';
    this.res = await this.connect.run(
      url,
      {
        hq_regional_id: this.form.hq_regional_id,
      },
      { region: true }
    );
    if (this.res.rsCode !== 0) {
      this.toast.present({ color: 'warning', message: this.res.rsMsg });
    }else if(this.res.rsCode === 0){
      this.res.rsMap = this.res.rsMap.filter((item)=> {
        if(this.form.search_text === ''){
          if(!item.district_url.includes('http')){
            item.district_url = 'https://' + item.district_url;
          }
          return true;
        }else if(item.district_name.includes(this.form.search_text)){
          if(!item.district_url.includes('http')){
            item.district_url = 'https://' + item.district_url;
          }
          return true;
        }else return false;
      })
    }else{

    }
  }

  /**
   * 지역 본부 목록
   */
  changeRegion() {
    this.getDistrictList();
  }

  /**
   * 사업 지구 목록
   */
  changeDistrict(item) {
    this._modal_.dismiss(item);
  }
}
