import {
  Component,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnInit,
  Output,
  ChangeDetectorRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Color } from '@ionic/core';
import { format } from 'path';
import { SearchDangerousAreaComponent } from '../../modal/search-dangerous-area/search-dangerous-area.component';

export class AreaDate {
  area_top_name: string = '';
  area_middle_name: string = '';
  area_bottom_name: string = '';
  ctgo_area_risk_name: string = '';
  area_risk_name: string = '';
  area_risk_id: number = 0;
}
@Component({
  selector: 'app-select-dangerous-area',
  templateUrl: './select-dangerous-area.component.html',
  styleUrls: ['./select-dangerous-area.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectDangerousAreaComponent),
      multi: true,
    },
  ],
})
export class SelectDangerousAreaComponent
  implements OnInit, ControlValueAccessor
{
  @HostListener('click') onClick() {
    if (this.mode) return;
    if (!this.disabled) this.dangerous();
  }

  @Input() color: Color;
  @Input() multiple: boolean = false;
  @Input() label: string = '위험지역';
  @Input() text: string;
  @Input() disabled: boolean = false;
  @Input() area_data: AreaDate = new AreaDate();
  @Input() required: boolean = false;
  @Input() readonly: boolean = true;
  @Input() mode: string = null;
  // @Input() working_area:string = '';
  private _project_id: number = 0;
  @Input() set project_id(v: number) {
    if (this._project_id !== v) {
      this._project_id = v;
      if (this.mode == 'ptw') {
        console.log('ptw모드?');
      } else {
        this.value = new AreaDate();
      }
    }
  }
  get project_id() {
    return this._project_id;
  }

  // data:AreaDate = new AreaDate();

  constructor(
    private _modal: ModalController,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  get() {
    console.log('this.value', this.value, typeof this.value);
    if (typeof this.value == 'object' && this.value !== null) {
      this.text =
        (this.value.area_top_name ? this.value.area_top_name : '') +
        ' ' +
        (this.value.area_middle_name ? this.value.area_middle_name : '') +
        ' ' +
        (this.value.area_bottom_name ? this.value.area_bottom_name + '/' : '') +
        (this.value.ctgo_area_risk_name
          ? this.value.ctgo_area_risk_name + '/'
          : '') +
        (this.value.area_risk_name ? this.value.area_risk_name : '');
    } else if (typeof this.value == 'string' && this.value !== null) {
      // console.log('AreaDate타입 아님', this.value);
      this.text = this.value;
    } else {
      // console.log('아무데도 아님');
      this.value = 'test';
      this.text = this.value;
    }
    this.changeDetector.detectChanges();
  }

  async dangerous() {
    const modal = await this._modal.create({
      component: SearchDangerousAreaComponent,
      componentProps: {
        project_id: this.project_id,
      },
    });
    modal.present();
    const { data } = await modal.onDidDismiss();
    if (data) {
      this.text =
        (data.area_top_name ? data.area_top_name : '') +
        ' ' +
        (data.area_middle_name ? data.area_middle_name : '') +
        ' ' +
        (data.area_bottom_name ? data.area_bottom_name : '') +
        '/' +
        (data.ctgo_area_risk_name ? data.ctgo_area_risk_name : '') +
        '/' +
        (data.area_risk_name ? data.area_risk_name : '');
      this.value = data;
      this.area_data = data;
    }
  }

  changeValue(newValue) {
    if (typeof newValue !== 'string') return;
    this.value = newValue;
    this.text = this.value;
    console.log('this.text', this.value, this.text);
  }

  clickEvent() {
    if (!this.disabled) this.dangerous();
  }

  @Output() change = new EventEmitter();

  private _value: AreaDate | string;
  @Input() set value(v: AreaDate | string) {
    console.log('set Value', v);
    if (v !== this._value) {
      if (typeof v == 'object') {
        this._value = v;
        this.get();
        this.onChangeCallback(v);
        this.change.emit(v);
      } else {
        // console.log('string');
        this._value = v;
        this.get();
        this.onChangeCallback(v);
        this.change.emit(v);
      }
    }
  }

  get value() {
    return this._value;
  }

  writeValue(v: AreaDate | string): void {
    console.log('writeValue', v);
    this._value = v;
    this.get();
    this.onChangeCallback(v);
    this.change.emit(v);
  }

  private onChangeCallback = (v) => {};
  private onTouchedCallback = (v) => {};
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }
}
