import { Component, Input, OnInit } from '@angular/core';
import { ModalController, ActionSheetController } from '@ionic/angular';
import { TbmJobListForm, TbmUnitPage } from '../tbm-unit/tbm-unit.page';
import { SearchAttendanceComponent } from 'src/app/component/modal/search-attendance/search-attendance.component';
import { QrSafetyInPage } from '../../../work-plan/work-minutes/qr-safety-in/qr-safety-in.page';
import {
  ConnectResult,
  ConnectService,
} from 'src/app/basic/service/core/connect.service';
import { UserService } from 'src/app/basic/service/core/user.service';
import { ToastService } from 'src/app/basic/service/ionic/toast.service';
import { DateService } from 'src/app/basic/service/util/date.service';
import { AlertService } from 'src/app/basic/service/ionic/alert.service';
import { TbmLeaderItem } from 'src/app/component/modal/search-tbm/search-tbm.component';
import {
  FileJson,
  FileService,
  FutItem,
} from 'src/app/basic/service/core/file.service';
import { config } from './apikey.js';
import { TbmListItem } from '../tbm-list/tbm-list.page';
import { QrTbmPage } from '../qr-tbm/qr-tbm.page';
import { PeopleDeleteTbmComponent } from 'src/app/component/modal/people-delete-tbm/people-delete-tbm.component';
import { ScannerService } from 'src/app/basic/service/util/scanner.service';
import { HttpClient } from '@angular/common/http';
import { QrService } from 'src/app/basic/service/util/qr.service';

/**
 * Tbm 기본 폼
 */
export class TbmDetailDefaultForm {
  project_id: number = 0; // 현장 ID
  project_name: string = ''; // 현장명
  company_id: number = 0; // 업체 ID
  company_name: string = ''; // 업체명
  user_id: number = 0; // 작성자 ID
  user_name: string = ''; // 작성자명
  working_date: string = ''; // 작업일
}
/**
 * Tbm 상세 폼
 */
export class TbmDetailForm {
  ctgo_construction_id: number; // 공종 ID
  instruction_content: string; // 지시 사항
  create_user_id: number; // 생성자 ID
  company_id: number; // 회사 ID
  leader_name: string; // TBM 리더 이름
  user_name: string; // 회원 이름
  working_contents_data: string; // 작성내용
  ctgo_construction_name: string; // 공종명
  tbm_file_data: string; // 파일 데이터
  tbm_id: number; // TBM ID
  tbm_state: string; // 작업 상태
  project_id: number; // 현장 ID
  working_area: string; // 작업 지역
  leader_user_id: number; // TBM 리더 ID
  create_date: string; // 생성일
  working_date: string; // 작업일
  project_name: string;
  company_name: string;
}

export type TbmViewType = 'CREATE' | 'READ' | 'EDIT' | 'START' | 'STOP';

@Component({
  selector: 'app-tbm-edit',
  templateUrl: './tbm-edit.page.html',
  styleUrls: ['./tbm-edit.page.scss'],
})
export class TbmEditPage implements OnInit {
  @Input() item?: TbmListItem;

  // 현재 뷰 타입
  private currentViewType: TbmViewType = 'CREATE';

  defaultRes: ConnectResult<TbmDetailDefaultForm>;
  detailRes: ConnectResult<TbmDetailForm>;

  /**
   * 기본 Response Form
   */
  defaultForm: TbmDetailDefaultForm = {
    project_id: 0,
    project_name: '',
    company_id: 0,
    company_name: '',
    user_id: 0,
    user_name: '',
    working_date: this.date.today(),
  };

  origin_instruction_content = '';

  /**
   * 상세 Response Form
   */
  detailForm: TbmDetailForm = {
    ctgo_construction_id: 0, // 공종 ID
    instruction_content: '', // 지시 사항
    create_user_id: 0, // 생성자 ID
    company_id: 0, // 회사 ID
    leader_name: '', // TBM 리더 이름
    user_name: '', // 회원 이름
    working_contents_data: '', // 작성내용
    ctgo_construction_name: '', // 공종명
    tbm_file_data: '', // 파일 데이터
    tbm_id: 0, // TBM ID
    tbm_state: '', // 작업 상태
    project_id: 0, // 현장 ID
    working_area: '', // 작업 지역
    leader_user_id: 0, // TBM 리더 ID
    create_date: '', // 생성일
    working_date: '', // 작업일
    project_name: '',
    company_name: '',
  };

  editable = {
    isAdmin: false, // 생성 및 수정 시
    isCreate: false, // 생성 시
    isEdit: false, // 수정 시
    isWork: false, // 작업 중인지 여부
  };

  isToday:boolean = false;

  viewEditable = {
    workingDate: false,
    contruction: false,
    workArea: false,
    tbmLeader: false,
    workState: true,
    workContents: false,
    isWorkContentsAdd: true,
    instructionContents: false,
    isAddFile: true,
    readFile: false,
    isAddAttend: false,
    isReadAttend: false,
  };

  tbm_file_data: FutItem[] = [];
  tbm_file: (File | Blob)[] = [];
  tbm_file_json: FileJson = new FileJson();

  company_id: number = 0;
  form = {
    project_id: this.user.userData.belong_data.project_id, // 현장 ID
    company_id: this.user.userData.belong_data.master_company_id
      ? this.user.userData.belong_data.master_company_id
      : this.user.userData.belong_data.company_id, // 원청사 ID
    ctgo_construction_id: 0, // 공종 ID
    file: this.tbm_file, // 파일
    file_json: this.tbm_file_json,
    instruction_content: '', // 지시 사항
    leader_user_id: 0, // TBM 리더 ID
    tbm_state: '작업 전', // 작업 상태 / 작업 전, 작업 중, 작업 종료
    working_area: '', // 작업 위치
    working_contents_json: [],
    working_date: '', // 작업일
    tbm_file_data: [],

    user_name: '', // 유저 이름
    attendance_list: [], // 출석자 정보
    tbm_leader_name: null,
    tbm_id: 0, // tbm_id
    create_user_id: 0,

    ctgo_construction_name: '',
    project_name: '',
    company_name: '',
    leader_name: '',
  };

  jobList: TbmJobListForm[] = [];
  // 출석한 작업자 아이디
  user_id = [];
  attentionList = [];
  search_text = '';

  constructor(
    private _modal: ModalController,
    private alert: AlertService,
    private date: DateService,
    private toast: ToastService,
    private connect: ConnectService,
    public user: UserService,
    private fileService: FileService,
    private qr: QrService,
    private actionSheetCtrl: ActionSheetController,
    private http: HttpClient
  ) {}

  ngOnInit() {
    if (this.item != null) {
      this.currentViewType = 'READ';
      this.company_id = this.user.userData.belong_data.master_company_id
        ? this.user.userData.belong_data.master_company_id
        : this.user.userData.belong_data.company_id;
      this.setEditable(this.currentViewType);
      this.getDetailGet();
    } else {
      this.company_id = this.user.userData.belong_data.master_company_id;
      this.editable.isAdmin = true;
      this.setEditable(this.currentViewType);
      this.getDefaultGet();
    }

    this.tab();
  }
  contructions = {};
  contructionsKeys = [];
  createTable(json) {
    this.contructions = {};

    for (let item of json) {
      const constructionName = item.risk_construction_name;
      const unitName = item.risk_unit_name;
      const planName = item.risk_plan_name;
      const factorName = item.risk_factor_name;
      if (this.contructions[constructionName]) {
        if (this.contructions[constructionName][unitName]) {
          this.contructions[constructionName][unitName].push({
            risk_plan_name: planName || '-',
            risk_factor_name: factorName || '-',
          });
        } else {
          this.contructions[constructionName][unitName] = [
            {
              risk_plan_name: planName || '-',
              risk_factor_name: factorName || '-',
            },
          ];
        }
      } else {
        this.contructions[constructionName] = {};
        this.contructions[constructionName][unitName] = [
          {
            risk_plan_name: planName || '-',
            risk_factor_name: factorName || '-',
          },
        ];
      }
    }

    this.contructionsKeys = Object.keys(this.contructions);
    for (let key of Object.keys(this.contructions)) {
      const contruction = this.contructions[key];
      let childLength = 0;
      for (let unitKey of Object.keys(contruction)) {
        const unit = contruction[unitKey];
        const unitLength = unit.length;
        childLength += unitLength;
      }
      this.contructions[key]['keys'] = Object.keys(contruction);
      this.contructions[key]['length'] = childLength;
      this.contructions[key]['dummy'] = Array(childLength).fill(0);
    }
  }

  /**
   * 활성화 설정
   * @param viewType
   */
  private setEditable(viewType: TbmViewType) {
    this.currentViewType = viewType;
    this.editable = {
      isAdmin:
        viewType === 'CREATE'
          ? true
          : this.detailForm.leader_user_id === this.user.userData.user_id ||
            this.detailForm.create_user_id === this.user.userData.user_id, // 생성 및 수정 시
      isCreate: false, // 생성 시
      isEdit: false, // 수정 시
      isWork: false, // 작업 중인지 여부
    };

    switch (this.currentViewType) {
      case 'CREATE':
        this.editable.isCreate = true;

        this.viewEditable = {
          workingDate: false,
          contruction: false,
          workArea: false,
          tbmLeader: false,
          workState: true,
          workContents: false,
          isWorkContentsAdd: true,
          instructionContents: false,
          isAddFile: true,
          readFile: true,
          isAddAttend: false,
          isReadAttend: false,
        };
        break;
      case 'READ':
        this.editable.isEdit = false;
        this.viewEditable = {
          workingDate: true,
          contruction: true,
          workArea: true,
          tbmLeader: true,
          workState: true,
          workContents: true,
          isWorkContentsAdd: false,
          instructionContents: true,
          isAddFile: false,
          readFile: true,
          isAddAttend: this.editable.isAdmin,
          isReadAttend: true,
        };
        break;
      case 'EDIT':
        this.editable.isEdit = true;
        this.viewEditable = {
          workingDate: false,
          contruction: false,
          workArea: false,
          tbmLeader: false,
          workState: true,
          workContents: false,
          isWorkContentsAdd: true,
          instructionContents: false,
          isAddFile: true,
          readFile: true,
          isAddAttend: false,
          isReadAttend: false,
        };
        break;
      case 'START':
        this.editable.isWork = true;

        this.viewEditable = {
          workingDate: true,
          contruction: true,
          workArea: true,
          tbmLeader: true,
          workState: true,
          workContents: true,
          isWorkContentsAdd: false,
          instructionContents: true,
          isAddFile: false,
          readFile: true,
          isAddAttend: this.editable.isAdmin,
          isReadAttend: true,
        };
        break;
      case 'STOP':
        this.editable.isWork = false;

        this.viewEditable = {
          workingDate: true,
          contruction: true,
          workArea: true,
          tbmLeader: true,
          workState: true,
          workContents: true,
          isWorkContentsAdd: false,
          instructionContents: true,
          isAddFile: false,
          readFile: true,
          isAddAttend: this.editable.isAdmin,
          isReadAttend: true,
        };
        break;
    }
    console.log('editable', this.editable);
  }

  /**
   * TBM 기본정보 조회
   */
  private async getDefaultGet() {
    const url = `/tbm/detail/default/get`;
    // http 통신
    this.defaultRes = await this.connect.run(url, null, { loading: true });
    // 서버 데이터가 에러 혹은 문구 표출 시 나오는 함수
    if (this.defaultRes.rsCode !== 0) {
      this.toast.present({ color: 'warning', message: this.defaultRes.rsMsg });
    } else {
      this.defaultForm = this.defaultRes.rsMap[0];
      this.defaultForm.working_date = this.date.today();
      this.form = {
        company_id: this.defaultForm.company_id, // 업체 ID
        ctgo_construction_id: 0, // 공종 ID
        file: this.tbm_file, // 파일
        file_json: this.tbm_file_json,
        instruction_content: '', // 지시 사항
        leader_user_id: 0, // TBM 리더 ID
        project_id: this.defaultForm.project_id, // 현장 ID
        tbm_state: '작업 전', // 작업 상태 / 작업 전, 작업 중, 작업 종료
        working_area: '', // 작업 위치
        working_contents_json: [],
        working_date: this.defaultForm.working_date, // 작업일
        tbm_file_data: [],
        user_name: this.defaultForm.user_name,
        attendance_list: [],
        tbm_leader_name: null,
        tbm_id: 0,
        create_user_id: this.user.userData.user_id,
        project_name: '',
        company_name: '',
        ctgo_construction_name: '',
        leader_name: '',
      };
    }
  }

  /**
   * TBM 상세 조회
   */
  private async getDetailGet($event?) {
    const url = '/tbm/detail/get';
    if (this.item) {
      this.form.tbm_id = this.item.tbm_id;
      this.defaultForm.company_id = this.item.company_id;
      this.form.project_id = this.item.project_id;
      this.form.company_id = this.item.company_id;
    }

    const form = {
      tbm_id: this.form.tbm_id,
      project_id: this.form.project_id,
      company_id: this.form.company_id,
    };
    this.detailRes = await this.connect.run(url, form, { loading: true });
    if (this.detailRes?.rsCode !== 0) {
      this.toast.present({ color: 'warning', message: this.detailRes?.rsMsg });
    } else {
      this.detailForm = this.detailRes?.rsObj;
      this.form = {
        company_id: this.detailForm.company_id, // 업체 ID

        ctgo_construction_id: this.detailForm.ctgo_construction_id, // 공종 ID
        file: this.tbm_file, // 파일
        file_json: this.tbm_file_json,
        instruction_content: this.detailForm.instruction_content, // 지시 사항
        leader_user_id: this.detailForm.leader_user_id, // TBM 리더 ID
        project_id: this.detailForm.project_id, // 현장 ID
        tbm_state: this.detailForm.tbm_state, // 작업 상태 / 작업 전, 작업 중, 작업 종료
        working_area: this.detailForm.working_area, // 작업 위치
        working_contents_json: JSON.parse(
          this.detailForm.working_contents_data
        ),
        working_date: this.detailForm.working_date, // 작업일
        tbm_file_data: JSON.parse(this.detailForm.tbm_file_data),
        user_name: this.detailForm.user_name,
        attendance_list: [],
        tbm_leader_name: this.detailForm.leader_name,
        tbm_id: this.detailForm.tbm_id,
        create_user_id: this.detailForm.create_user_id,
        project_name: this.detailForm.project_name,
        company_name: this.detailForm.company_name,
        leader_name: this.detailForm.leader_name,
        ctgo_construction_name: this.detailForm.ctgo_construction_name,
      };
      this.editable.isAdmin =
        this.detailForm.leader_user_id === this.user.userData.user_id ||
        this.detailForm.create_user_id === this.user.userData.user_id;
      this.tbm_file_data = this.form.tbm_file_data;

      this.isToday = this.date.today() === this.form.working_date;

      await this.getAttendanceList(this.form.tbm_id);

      for (let working_content of this.form.working_contents_json) {
        let tbmJobForm = new TbmJobListForm();
        tbmJobForm.seq_no = working_content.seq_no;
        tbmJobForm.risk_construction_name =
          working_content.risk_construction_name;
        tbmJobForm.risk_factor_name = working_content.risk_factor_name;
        tbmJobForm.risk_plan_name = working_content.risk_plan_name;
        tbmJobForm.risk_unit_name = working_content.risk_unit_name;
        this.jobList.push(tbmJobForm);
      }

      this.setEditable('READ');
      this.changeState(this.detailForm.tbm_state);
      this.createTable(this.form.working_contents_json);

      // 상세 접속 시 작업일자가 지났으며, 작업 종료 상태가 아닌 항목에 대해 작업종료 처리
      // if (
      //   this.date.dirrerence(this.form.working_date, this.date.today()) > 0 &&
      //   this.form.tbm_state !== '작업종료'
      // ) {
      //   await this.updateStateTbm('작업종료');
      // }
    }
    if ($event) {
      setTimeout(() => {
        $event.target.complete();
      }, 50);
    }
  }

  /**
   * Http Post
   * TBM 출석 현황 조회
   * @param tbm_id
   */
  private async getAttendanceList(tbm_id: number, $event?) {
    const url = '/tbm/attendance/list/get';
    const res: ConnectResult = await this.connect.run(url, {
      tbm_id: tbm_id,
    });
    if (res.rsCode === 0) {
      this.form.attendance_list = [];
      this.attentionList = [];
      this.user_id = [];
      for (let i = 0; i < res.rsMap.length; i++) {
        const attend = res.rsMap[i];
        attend.index = i;
        this.user_id.push(attend.user_id);
        this.form.attendance_list.push(attend);
        this.attentionList.push(attend);
      }
    } else if (res.rsCode === 1008) {
      this.user_id = [];
      this.form.attendance_list = [];
      this.attentionList = [];
    }
    if ($event) {
      setTimeout(() => {
        $event.target.complete();
      }, 50);
    }
  }

  /**
   * Tbm Leader 가 변경되면 오는 Callback
   * @param tbmLeader
   */
  changeTbmLeader(tbmLeader?: TbmLeaderItem) {
    if (tbmLeader) {
      this.form.leader_user_id = tbmLeader.user_id;
    }
  }

  QRscanner() {
    // this.scanner.init();
    this.qr.open();
  }

  /**
   * 현재 뷰 타입 변경
   * @param type
   */
  changeViewType(type: TbmViewType) {
    this.setEditable(type);
  }

  /**
   * Http Post
   * TBM 저장
   * this.currentViewType 에 따라 나뉨
   */
  async saveTbm() {
    this.alert.present({
      message: '입력된 내용으로 저장하시겠습니까?',
      buttons: [
        { text: '아니오' },
        {
          text: '예',
          handler: async () => {
            if (this.currentViewType === 'CREATE') {
              this.createTbm();
            } else if (this.currentViewType === 'EDIT') {
              this.editTbm();
            }
          },
        },
      ],
    });
  }

  /**
   * Http Post
   * TBM 생성
   * %생성 함수는 생성 모드시에만 사용%
   */
  async createTbm() {
    if (this.currentViewType !== 'EDIT' && this.currentViewType !== 'CREATE')
      return;

    this.form.company_id = this.company_id;
    this.form.project_id = this.defaultForm.project_id;

    this.form.working_contents_json = [];
    if (this.jobList.length > 0) {
      for (let job of this.jobList) {
        this.form.working_contents_json.push({
          seq_no: job.seq_no,
          risk_construction_name: job.risk_construction_name || '-',
          risk_unit_name: job.risk_unit_name || '-',
          risk_factor_name: job.risk_factor_name || '-',
          risk_plan_name: job.risk_plan_name || '-',
        });
      }
    }

    this.form.working_date = this.defaultForm.working_date;
    const url = '/tbm/insert';
    const customForm = {
      company_id: this.form.company_id, // 업체 ID
      ctgo_construction_id: this.form.ctgo_construction_id, // 공종 ID
      file: this.tbm_file, // 파일
      file_json: this.form.file_json,
      instruction_content: this.form.instruction_content, // 지시 사항
      leader_user_id: this.form.leader_user_id, // TBM 리더 ID
      project_id: this.form.project_id, // 현장 ID
      tbm_state: this.form.tbm_state, // 작업 상태 / 작업 전, 작업 중, 작업 종료
      working_area: this.form.working_area, // 작업 위치
      working_contents_json: this.form.working_contents_json,
      working_date: this.form.working_date, // 작업일
    };

    if (
      customForm.file_json.insert.length < 1 &&
      customForm.file_json.update.length < 1 &&
      customForm.file_json.delete.length < 1
    ) {
      customForm.file_json = { insert: [], update: [], delete: [] };
    }

    // 데이터 체크
    let isChecked = true;
    if (customForm.ctgo_construction_id === 0) {
      isChecked = false;
    }

    if (customForm.leader_user_id === 0) {
      isChecked = false;
    }

    if (customForm.working_area.trim() === '') {
      isChecked = false;
    }

    if (customForm.working_contents_json.length < 1) {
      isChecked = false;
    }

    // 데이터 부족
    if (!isChecked) {
      this.toast.present({
        color: 'warning',
        message: '기본정보와 작업 내용을 입력해주세요',
      });
      return;
    }

    const data = await this.connect.run(url, customForm, {
      loading: true,
    });
    if (data.rsCode !== 0) {
      this.toast.present({ color: 'warning', message: data.rsMsg });
    } else {
      window.dispatchEvent(new CustomEvent('tbm-list:get()'));
      this.toast.present({ message: data.rsMsg, color: 'primary' });

      this.form.tbm_id = data.rsObj.tbm_id;
      this.form.company_id = this.company_id;
      this.form.project_id = this.defaultForm.project_id;
      // 여기서 작성 완료 화면으로 전환
      this.getDetailGet();
    }
  }

  /**
   * Http Post
   * Tbm 수정
   */
  async editTbm() {
    if (this.currentViewType !== 'EDIT') return;
    const url = '/tbm/update';

    this.form.working_contents_json = [];
    if (this.jobList.length > 0) {
      for (let job of this.jobList) {
        this.form.working_contents_json.push({
          seq_no: job.seq_no,
          risk_construction_name: job.risk_construction_name,
          risk_unit_name: job.risk_unit_name,
          risk_factor_name: job.risk_factor_name,
          risk_plan_name: job.risk_plan_name,
        });
      }
    }
    // 변경된 값이 들어가야 함
    const localForm = {
      tbm_id: this.form.tbm_id,
      company_id: this.company_id,
      project_id: this.form.project_id,
      ctgo_construction_id: this.form.ctgo_construction_id,
      leader_user_id: this.form.leader_user_id,
      instruction_content: this.form.instruction_content,
      working_area: this.form.working_area,
      working_contents_json: this.form.working_contents_json,
      tbm_state: this.form.tbm_state,
      file: this.tbm_file,
      file_json: this.form.file_json,
    };
    const res: ConnectResult = await this.connect.run(url, localForm, {
      loading: true,
    });
    if (res.rsCode !== 0) {
      this.toast.present({ color: 'warning', message: res.rsMsg });
    } else {
      window.dispatchEvent(new CustomEvent('tbm-list:get()'));
      this.setEditable('READ');
    }
  }

  /**
   * Http
   * TBM 삭제하기
   */
  async deleteTbm() {
    this.alert.present({
      message: 'TBM을 삭제 하시겠습니까?',
      buttons: [
        { text: '아니오' },
        {
          text: '예',
          handler: async () => {
            console.log('delete tbm', this.currentViewType);
            if (this.currentViewType === 'CREATE') {
              this._modal.dismiss({ state: 'DELETE SUCCESS' });
            } else {
              const url = '/tbm/delete';
              const data = await this.connect.run(url, {
                company_id: this.company_id,
                ctgo_construction_id: this.detailForm.ctgo_construction_id,
                project_id: this.detailForm.project_id,
                tbm_id: this.detailForm.tbm_id,
                tbm_state: this.detailForm.tbm_state,
              });
              if (data.rsCode !== 0) {
                this.toast.present({ color: 'warning', message: data.rsMsg });
              } else {
                window.dispatchEvent(new CustomEvent('tbm-list:get()'));
                this._modal.dismiss({ state: 'DELETE SUCCESS' });
              }
            }
          },
        },
      ],
    });
  }

  /**
   * Http
   * TBM 상태 변경하기
   */
  async updateStateTbm(state: string) {
    const url = '/tbm/update/state';
    const data = await this.connect.run(url, {
      company_id: this.detailForm.company_id,
      project_id: this.detailForm.project_id,
      tbm_id: this.detailForm.tbm_id,
      tbm_state: state,
    });
    if (data.rsCode !== 0) {
      this.toast.present({ color: 'warning', message: data.rsMsg });
    } else {
      window.dispatchEvent(new CustomEvent('tbm-list:get()'));
      this.changeState(state);
      this.getDetailGet();
    }
  }

  changeWorkingDate() {
    this.contructions = {};
    this.contructionsKeys = [];
  }

  /**
   * 작업 상태 변경 -> 뷰 타입 변경
   * @param strState 작업 전 / 작업 중 / 작업종료
   */
  changeState(strState: string) {
    switch (strState) {
      case '작업 전':
        this.setEditable('READ');
        break;
      case '작업 중':
        this.setEditable('START');
        break;
      case '작업종료':
        this.setEditable('STOP');
        break;
    }
  }

  /**
   * 탭 스크립트
   */
  tab() {
    const tabBtn = document.querySelectorAll('.tab_btn');
    const tabCont = document.querySelectorAll('.tab_edit');
    const tabFoot = document.querySelectorAll('.btn_foot');

    for (let i = 0; i < tabBtn.length; i++) {
      tabBtn[i].addEventListener('click', () => {
        for (let i = 0; i < tabCont.length; i++) {
          tabCont[i].classList.remove('active');
          tabFoot[i].classList.remove('active');
        }
        tabCont[i].classList.add('active');
        tabFoot[i].classList.add('active');
      });
    }
  }

  /**
   * 사용자 상세 정보
   * @param userInfo
   */
  async userInfo(userInfo) {
    const modal = await this._modal.create({
      component: PeopleDeleteTbmComponent,
      componentProps: {
        item: userInfo,
        account_id: userInfo.account_id,
        ...{
          menu_state: this.currentViewType == 'EDIT',
          my_state: this.editable.isAdmin,
        },
      },
    });
    modal.present();
    const { data } = await modal.onDidDismiss();
    if (data) {
      this.getAttendanceList(userInfo.tbm_id);
    }
  }

  /**
   * 단위작업 선택
   * 기존 API
   */
  async unitOrigin() {
    const res = await this.connect.run('/tbm/detail/unit-operation/list/get', {
      query_date: this.form.working_date,
    });
    if (res.rsCode === 0) {
      const modal = await this._modal.create({
        component: TbmUnitPage,
        componentProps: {
          jobList: res.rsMap,
        },
      });
      modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        this.jobList = data.tbmJob;
        const json = [];
        if (this.jobList.length > 0) {
          for (let job of this.jobList) {
            json.push({
              seq_no: job.seq_no,
              risk_construction_name: job.risk_construction_name || '-',
              risk_unit_name: job.risk_unit_name || '-',
              risk_factor_name: job.risk_factor_name || '-',
              risk_plan_name: job.risk_plan_name || '-',
            });
          }
        }
        this.createTable(json);
      }
    } else if (res.rsCode === 1008) {
      this.alert.present({
        message: '작업일에 해당하는 평가표 정보가 없습니다',
      });
    } else {
      this.toast.present({ color: 'warning', message: res.rsMsg });
    }
  }


/**
   * Http 통신 Post
   * 위험성 평가표 Default Table 형식 불러오는 API 사용
   */
 async unit() {
  const url = '/project/inspection/daily/risk-asment/list/get';
  const res = await this.connect.run(
    url,
    {
      project_id: this.form.project_id,
      company_id: this.form.company_id,
      inspection_date: this.form.working_date,
    },
    { loading: true }
  );

  if(res.rsCode === 0){
    let index = 1;
    res.rsMap.map((item)=> {
      item.seq_no = index;
      index++;
    })

    const modal = await this._modal.create({
      component: TbmUnitPage,
      componentProps: {
        jobList: res.rsMap,
      },
    });
    modal.present();
    const { data } = await modal.onDidDismiss();
    if (data) {
      this.jobList = data.tbmJob;
      const json = [];
      if (this.jobList.length > 0) {
        for (let job of this.jobList) {
          json.push({
            seq_no: job.seq_no,
            risk_construction_name: job.risk_construction_name || '-',
            risk_unit_name: job.risk_unit_name || '-',
            risk_factor_name: job.risk_factor_name || '-',
            risk_plan_name: job.risk_plan_name || '-',
          });
        }
      }
      this.createTable(json);
    }
  } else if (res.rsCode === 1008) {
    this.alert.present({
      message: '작업일에 해당하는 평가표 정보가 없습니다',
    });
  } else {
    this.toast.present({ color: 'warning', message: res.rsMsg });
  }
}


  /**
   * 사용자 팝업
   */
  async worker(userInfo) {
    const modal = await this._modal.create({
      component: PeopleDeleteTbmComponent,
      componentProps: {
        userInfo,
        ...{
          menu_state: this.currentViewType == 'EDIT',
          my_state:
            this.detailForm.create_user_id === this.user.userData.user_id,
        },
      },
    });
    modal.present();
    const { data } = await modal.onDidDismiss();
    if (data) {
    }
  }

  /**
   * 출석 체크
   */
  async eduAdd() {
    const modal = await this._modal.create({
      component: SearchAttendanceComponent,
      componentProps: {
        project_id: this.user.userData.belong_data.project_id,
        company_id: this.user.userData.belong_data.company_id,
        educationType: false,
        value: this.user_id,
        title: '근로자',
      },
    });
    modal.present();
    const { data } = await modal.onDidDismiss();
    if (data) {
      let deletedUser = this.fileService.clone(this.user_id);
      const responseIds = data.map((item) => item.user_id);
      let temp = deletedUser.filter((item) => !responseIds.includes(item));
      deletedUser = temp;
      console.log('deletedUser', this.user_id, responseIds, deletedUser);
      let isAdd = false;
      responseIds.forEach((item) => {
        if (!this.user_id.includes(item)) {
          isAdd = true;
          this.user_id.push(item);
        }
      });

      const attendanceResults = [];
      if (isAdd) {
        for (let userId of this.user_id) {
          // TBM 출석
          const res = await this.connect.run('/tbm/attendance/sign', {
            attend_user_id: userId,
            tbm_id: this.item.tbm_id,
          });
          attendanceResults.push(res);
        }

        const errAttendance = attendanceResults.filter(
          (res) => res.rsCode !== 0
        );
        if (errAttendance.length > 0) {
          this.toast.present({
            message: errAttendance[0].rsMsg,
            color: 'warning',
          });
        } else {
          this.toast.present({
            message: '선택하신 인원이 출석되었습니다.',
            color: 'primary',
          });
        }
      }

      const deletedResults = [];
      if (deletedUser.length > 0) {
        for (let userId of deletedUser) {
          // TBM 출석
          const res = await this.connect.run('/tbm/attendance/exclude', {
            attend_user_id: userId,
            tbm_id: this.item.tbm_id,
          });
          deletedResults.push(res);
        }

        const errAttendance = deletedResults.filter((res) => res.rsCode !== 0);
        if (errAttendance.length > 0) {
          this.toast.present({
            message: errAttendance[0].rsMsg,
            color: 'warning',
          });
        } else {
          this.toast.present({
            message: '선택하신 인원이 제외되었습니다.',
            color: 'primary',
          });
        }
      }
      this.getAttendanceList(this.item.tbm_id);
    }
  }

  async translate() {
    const actionSheet = await this.actionSheetCtrl.create({
      header: 'Are you sure?',
      buttons: [
        {
          text: '한국어',
          role: 'ko',
        },
        {
          text: 'English',
          role: 'en',
        },
        {
          text: '漢語',
          role: 'zh',
        },
        {
          text: 'ภาษาไทย',
          role: 'th',
        },
        { text: 'Tiếng Việt', role: 'vi' },
      ],
    });
    actionSheet.present();

    const { role } = await actionSheet.onWillDismiss();

    const url =
      'https://translation.googleapis.com/language/translate/v2?key=' +
      config.apikey;

    await this.http
      .post(url, {
        q: [this.form.instruction_content],
        target: role,
      })
      .subscribe((res: any) => {
        this.form.instruction_content = res.data.translations[0].translatedText;
      });
  }

  /**
   * 출석체크 QR
   */
  async qrAdd() {
    const modal = await this._modal.create({
      component: QrTbmPage,
      componentProps: {
        item: this.form,
      },
    });
    modal.present();
    const { data } = await modal.onDidDismiss();
    this.getAttendanceList(this.form.tbm_id);
  }

  /**
   * 근로자 검색
   */
  async search() {
    if (this.search_text === '') {
      this.attentionList = this.form.attendance_list;
    } else {
      this.attentionList = this.form.attendance_list.filter(
        (attend) =>
          attend.account_id.includes(this.search_text) ||
          attend.user_name.includes(this.search_text)
      );
    }
  }
}
