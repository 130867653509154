import { ToastService } from 'src/app/basic/service/ionic/toast.service';
import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { ConnectResult, ConnectService } from 'src/app/basic/service/core/connect.service';
import { ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { PromiseService } from 'src/app/basic/service/util/promise.service';
import { LanguagePackService } from 'src/app/basic/service/core/language-pack.service';
import { UserService } from 'src/app/basic/service/core/user.service';

export class ActionManager {
  company_id:number;
  ctgo_job_position_id: number;
  user_id: number;
  user_name: string;
  company_name: string;
  ctgo_job_position_name: string;
  user_safe_job_id: number;
  ctgo_safe_job_id: number;
  ctgo_safe_job_name: string;
  checked = false;
}

export type ProjectSearchType = 'SIGNUP' | 'SIGNUPLH' | null;
export class ProjectItem {
  project_name: string;
  project_id: number;
  project_code: string;
}
@Component({
  selector: 'app-search-action-people',
  templateUrl: './search-action-people.component.html',
  styleUrls: ['./search-action-people.component.scss'],
})
export class SearchActionPeopleComponent implements OnInit {

  @Input() value;
  @Input() type:ProjectSearchType;
  @Input() all:boolean = false;
  // @Input() form = {
  //   company_id: 0,
  //   search_text: ''
  // }

  form={
    project_id:this.user.userData.belong_data.project_id,
    company_id:this.user.userData.belong_data.company_id,
    user_type:'관리자',
    search_text:''
  }

  selectAll: boolean;
  values:ActionManager[] = [];
  res:ConnectResult<ActionManager>;

  allState:boolean = false; // 전체현장을 선택했는지?
  selectedItem:any;

  actionPeopleList:any = [];

  constructor(
    private el: ElementRef<HTMLElement>,
    private connect: ConnectService,
    private _modal : ModalController,
    private promise: PromiseService,
    private toast: ToastService,
    private user: UserService,
    public languagePack: LanguagePackService
  ) { }

  ngOnInit() {
    this.get();
    this.test();
  }

  public async test() {
    if(!environment.test.core.test) return;
    
    const el = this.el.nativeElement;

    // 가짜 데이터 삽입
    await this.promise.wait();

    // 검색
    el.querySelector('[name=search_text]').dispatchEvent(new Event('buttonClick'));
    await this.promise.wait();

    // 가장 위의 현장을 입력
    el.querySelector('[name=project_info]').dispatchEvent(new Event('click'));
    await this.promise.wait();

    // 선택함
    el.querySelector('[name=submit]').dispatchEvent(new Event('click'));
  }
  
  async get() {
    console.log("get this.form",this.form);
    this.res = await this.connect.run('/work_state/acha/action/manager/get', this.form, { loading: '현장 검색' });
    if (this.res.rsCode === 0) {
      if (this.value) {
        this.res.rsMap?.filter((item) => {
          if (this.value.indexOf(item.user_id) > -1) {
            item.checked = true;
          }
          // item.ctgo_job_position_name = item.ctgo_job_position_name_kr;
          // item.ctgo_safe_job_name = item.ctgo_safe_job_name_kr;
        });
      }
      // this.res.rsMap.filter(item => {
      //   if(this.value === item.project_id) this.selectedItem = item;
      // });
      if(!this.value) this.allState = true;
    } else {
      this.toast.present({ color: 'warning', message: this.res.rsMsg });
    }
  }

  isPreSelected(item) {
    // return this.preSelected?.find(preItem => preItem.answer_user_id === item.user_id);
    for(let name of this.actionPeopleList){
      if(name.user_id === item.user_id){
        return true;
      }
    }
  }

  dismiss() {
    this._modal.dismiss();
  }

  submit() {
    this._modal.dismiss(this.values);
  }

  async addCard(item:any) {
    this.actionPeopleList.push({
      user_name:item.user_name,
      user_id:item.user_id
    });
    this.selectedItem = item;
    console.log("item",item,this.actionPeopleList)
  }

  selectItem(item) {
    item.checked = !item.checked;
    if (item.checked) {
      if (!this.values.includes(item)) this.values.push(item);
    } else {
      const index = this.values.findIndex((data) => data === item);
      this.values.splice(index, 1);
    }
  }

  deleteData(i, name) {
    console.log(i);
    this.res.rsMap.forEach((item) => {
      if (item.user_id === name.user_id) {
        item.checked = false;
      }
    });
    this.values.splice(i, 1);
  }

  async getItem() {
    await this.promise.wait(() => this.res);
    if (this.value)
      [
        this.res?.rsMap?.filter((item) => {
          if (this.value.indexOf(item.user_id) > -1) {
            this.values.push(item);
            console.log(this.values);
            item.checked = true;
          }
        }),
      ];
  }

  // async submit() {
  //   this._modal.dismiss({
  //     selectedItem: this.selectedItem,
  //     peopleList:this.actionPeopleList,
  //     allState: this.allState
  //   })
  // }

  // select() {
  //   this._modal.dismiss({
  //     selectedItem: this.selectedItem,
  //     peopleList:this.actionPeopleList,
  //     allState: this.allState
  //   });
  // }


}