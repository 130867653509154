import { ToastService } from 'src/app/basic/service/ionic/toast.service';
import { Component, ElementRef, Input, OnInit } from '@angular/core';
import {
  ConnectResult,
  ConnectService,
} from 'src/app/basic/service/core/connect.service';
import { ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { PromiseService } from 'src/app/basic/service/util/promise.service';
import { LanguagePackService } from 'src/app/basic/service/core/language-pack.service';

export type ProjectSearchType = 'SIGNUP' | 'SIGNUPLH' | null;
export class ProjectItem {
  project_name: string;
  project_id: number;
  project_code: string;
}

export class TbmLeaderItem {
  company_id: number; // 업체 ID
  user_id: number; // 사용자 ID
  user_name: string; // 사용자 명
  company_name: string; // 업체명
}
@Component({
  selector: 'app-search-emergency',
  templateUrl: './search-emergency.component.html',
  styleUrls: ['./search-emergency.component.scss'],
})
export class SearchEmergencyComponent implements OnInit {
  @Input() value;
  @Input() form = {
    search_text: '',
  };
  res: ConnectResult<TbmLeaderItem>;

  allState: boolean = false; // 전체현장을 선택했는지? TBM 리더에서는 의미 없음
  selectedItem: TbmLeaderItem;

  constructor(
    private el: ElementRef<HTMLElement>,
    private connect: ConnectService,
    private _modal: ModalController,
    private promise: PromiseService,
    private toast: ToastService,
    public languagePack: LanguagePackService
  ) {}

  ngOnInit() {
    this.get();
    // this.test();
  }

  public async test() {
    if (!environment.test.core.test) return;

    const el = this.el.nativeElement;

    // 가짜 데이터 삽입
    await this.promise.wait();

    // 검색
    el.querySelector('[name=search_text]').dispatchEvent(
      new Event('buttonClick')
    );
    await this.promise.wait();

    // 가장 위의 TBM 리더 선택
    el.querySelector('[name=tbm_leaders]').dispatchEvent(new Event('click'));
    await this.promise.wait();

    // 선택함
    el.querySelector('[name=submit]').dispatchEvent(new Event('click'));
  }

  async get() {
    this.res = await this.connect.run('/tbm/detail/leader/list/get');
    if (this.res.rsCode === 0) {
      this.res.rsMap.filter((item) => {
        if (this.value === item.user_id) this.selectedItem = item;
      });
      if (!this.value) this.allState = true;
    } else {
      this.toast.present({ color: 'warning', message: this.res.rsMsg });
    }
  }

  select() {
    this._modal.dismiss({
      selectedItem: this.selectedItem,
    });
  }
}
